import React, { useState, useEffect } from "react";
// Tools
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import $ from "jquery";
// API calls
import { getActiveEnterprise } from "../Models/Enterprise_model";
import { getCollabByText, getsurveyLinksKiosco } from "../Models/Collaborators_model";
// Components
import SurveyModal from "./SurveyModal";

const KioskController = props => {
    // Globla variables
    const [collaboratorName, setCollaboratorName] = useState("");
    const [enterpriseID, setEnterpriseID] = useState(0);
    const [enterpriseData, setEnterpriseData] = useState({});
    const [collaboratorsList, setCollaboratorsList] = useState([]);
    const [hiddenBox, setHiddenBox] = useState(true);
    const [collaboratorID, setCollaboratorID] = useState(0);
    const [listCategory, setlistCategory] = useState([]);
    // useEffect
    useEffect(() => {
        let getURLParameters = props.match.params;
        let enterpriseID = atob(getURLParameters.db);
        getActiveEnterprise(enterpriseID).then((response) => {
            setEnterpriseData(response.enterprise[0]);
        });
        setEnterpriseID(enterpriseID);
        // eslint-disable-next-line
    }, []);
    /**
     * 
     * @param {event} e 
     */
    const getCollaborator = e => {
        e.preventDefault();
        let value = e.target.value;
        setCollaboratorName(value);
        setCollaboratorID(0);
        if (value === "") {
            setHiddenBox(true);
            setCollaboratorsList([]);
        } else {
            getCollabByText(enterpriseID, value).then((response) => {
                switch (response.code) {
                    case 1:
                        setHiddenBox(false);
                        setCollaboratorsList(response.listCollab);
                        break;
                    default:
                        setHiddenBox(true);
                        setCollaboratorsList([]);
                        break;
                }
            });
        }
    }
    /**
     * 
     * @param {Event} e 
     * @param {string} name 
     * @param {string} lastName 
     * @param {string} secondLastName 
     * @param {string} rfc 
     * @param {int} collaboratorId 
     */
    const selectCollaborator = (e, name, lastName, secondLastName, rfc, collaboratorId) => {
        e.preventDefault();
        setHiddenBox(true);
        setCollaboratorID(collaboratorId);
        setCollaboratorsList([]);
        setCollaboratorName(name + " " + lastName + " " + secondLastName + " <" + rfc + ">");
    }
    /**
     * 
     */
    const showSurveys = () => {
        if (collaboratorID === 0) {
            toastr.error('Selecciona un colaborador de la lista.', '¡Ooops!');
        } else {
            getsurveyLinksKiosco(collaboratorID, enterpriseID).then((response) => {
                switch (response.code) {
                    case 1:
                        let arrayAux = [];
                        // if (response.listProducts.length > 0) {
                        //     for (let x in response.listProducts) {
                        //         for (let y in response.listProducts[x].listCategory) {
                        //             arrayAux.push(response.listProducts[x].listCategory[y]);
                        //         }
                        //     }
                        //     setlistCategory(arrayAux);
                        //     $('#surveysModal').show();
                        // } else {
                        //     toastr.info('No cuentas con ejercicios por contestar.', '¡Ooops!');
                        // }
                        if (response.listProducts.length > 0 || response.customerSurveys.length > 0) {
                            if (response.listProducts.length > 0) {
                                for (let x in response.listProducts) {
                                    for (let y in response.listProducts[x].listCategory) {
                                        arrayAux.push(response.listProducts[x].listCategory[y]);
                                    }
                                }
                            }
                            if (response.customerSurveys.length > 0) {
                                let arrayCustomSurvey = [];
                                response.customerSurveys.forEach(element => {
                                    arrayCustomSurvey.push({ ...element, status: 2, imgWeb: response.imgCustomerSurveys });
                                });
                                arrayAux.push({
                                    title: "Encuestas personalizadas",
                                    listCustomSurvey: arrayCustomSurvey
                                });

                            }
                            let img = [
                                "./../img/inicioColaborador/icono_circulo_magenta.png",
                                "./../img/inicioColaborador/icono_circulo_verde.png",
                                "./../img/inicioColaborador/icono_circulo_azul.png",
                                "./../img/inicioColaborador/icono_circulo_amarillo.png",
                                "./../img/inicioColaborador/icono_circulo_gris.png",
                            ];
                            let surveyArr = [];
                            let counter = 0;
                            for (let x in arrayAux) {
                                if (counter === 4) {
                                    surveyArr.push({ ...arrayAux[x], icon_1: img[0], iconId: counter });
                                    counter = 1;
                                } else {
                                    if (arrayAux[x].idCategory === 1) {
                                        let newListSubCategorys = []
                                        for (let y in arrayAux[x].listSubCategorys) {
                                            newListSubCategorys.push({ ...arrayAux[x].listSubCategorys[y], icon_1: img[counter], iconId: counter })
                                            counter = counter + 1;
                                        }
                                        surveyArr.push({ ...arrayAux[x], listSubCategorys: newListSubCategorys });
                                    } else {
                                        surveyArr.push({ ...arrayAux[x], icon_1: img[counter], iconId: counter });
                                        counter = counter + 1;
                                    }
                                }
                            }
                            //console.log(surveyArr)
                            setlistCategory(surveyArr);
                            // $('#surveysModal').show();
                        } else {
                            // toastr.info('No cuentas con ejercicios por contestar.', '¡Ooops!');
                        }
                        break;
                    default:
                        toastr.info('No cuentas con ejercicios por contestar.', '¡Ooops!');
                        break;
                }
            });
            $('#surveysModal').show();
        }
    }
    return (
        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 p-0">
            <div className="row">
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                    <nav class="navbar navbar-expand-sm bg-white-1 py-1">
                        <img src="./../img/guay-logo.png" style={{ width: "15vh" }} alt="guay-logo" />
                    </nav>
                </div>
            </div>
            <div className="row">
                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-2 d-flex justify-content-center">
                    <span className="font-medium-5 font-weight-bold">Busca tus ejercicios</span>
                </div>
                <div className="col-sx-12 col-sm-12 col-md-10 col-lg-6 mx-auto mt-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                    <span className="font-medium-1 font-weight-bold">{enterpriseData.comercial_name}</span>
                                </div>
                                {enterpriseData.logo !== undefined /* || enterpriseData.logo !== "undefined" */
                                    ?
                                    (
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center mt-1">
                                            <img src={enterpriseData.logo} style={{ width: "10vh" }} alt="guay-img" />
                                        </div>
                                    )
                                    :
                                    (
                                        null
                                    )
                                }
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            maxLength="100"
                                            className="form-control"
                                            placeholder="Nombre del colaborador"
                                            id="collaborator"
                                            name="collaborator"
                                            autocomplete="off"
                                            onChange={getCollaborator}
                                            value={collaboratorName} />
                                        <div className="shadow-card-1 rounded select-option-style-1" hidden={hiddenBox}>
                                            {collaboratorsList.map((collaboratorsList) => (
                                                <span
                                                    key={collaboratorsList.collaboratorId}
                                                    value={collaboratorsList.collaboratorId}
                                                    className="d-block p-1 select-option-style-2 pointer"
                                                    onClick={(e) => selectCollaborator(e, collaboratorsList.namesCollab, collaboratorsList.lastName, collaboratorsList.secondLastName, collaboratorsList.rfc, collaboratorsList.collaboratorId)}
                                                >
                                                    {collaboratorsList.namesCollab + " " + collaboratorsList.lastName + " " + collaboratorsList.secondLastName + " <" + collaboratorsList.rfc + ">"}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1 d-flex justify-content-center">
                                    <button
                                        className="btn btn-blue-3 btn-sm"
                                        onClick={() => showSurveys()}>Ir a ejercicios</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SurveyModal listCategory={listCategory} enterpriseData={enterpriseData} isKiosk={true} collaboratorID = {collaboratorID}/>
        </div>
    )
}

export default KioskController;
