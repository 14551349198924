import React, { useState, useEffect, Fragment } from 'react';
// API calls
import { getWebinars } from '../Models/Catalog_model';
import { setWebinarView } from '../Models/WebinarModel';
// Components
import Header from '../NewHome/headerColaborador';
import Menu from "../Menu";
import HelperApp from '../HelperApp';
import Footer from '../footerApp';
import BannerWebinar from './BannerWebinar';
// Tools
import { redirectIfNotAuthenticated, getJwt } from "../../../lib/auth";
import ReactPlayer from 'react-player/lazy';

const Webinar = () => {
    const [webinars, setWebinars] = useState([]);
    const [playedWebinar, setPlayedWebinar] = useState([]);
    const [webinarInfo, setWebinarInfo] = useState([]);
    const [showBanner, setShowBanner] = useState(true);
    const [milestones, setMilestones] = useState({
        reached25: false,
        reached50: false,
        reached75: false,
        reached100: false,
    });

    useEffect(() => {
        getWebinars().then(response => {
            if (response) {
                switch (response.code) {
                    case 1:
                        let arr = []
                        for (let x in response.listWebinars) {
                            arr.push({ ...response.listWebinars[x], showVideo: false })
                        }
                        setWebinars(arr);
                        break;
                    default:
                        break;
                }
            }
        })
    }, []);

    // const handleTouch = id => {
    //     //console.log(id)
    //     let arr = []
    //     for (let x in webinars) {
    //         if (webinars[x].idWebinar === id) {
    //             //console.log(webinars[x].idWebinar, id)
    //             arr.push({ ...webinars[x], showVideo: true })
    //         } else {
    //             arr.push(webinars[x])
    //         }
    //     }
    //     //console.log(arr)
    //     setWebinars(arr);
    // }

    const handleTouch = id => {
        const arr = webinars.map(webinar => ({
            ...webinar,
            showVideo: webinar.idWebinar === id,
        }));
        setWebinars(arr);
    };

    const handleOnStart = (idWebinar) => {
        console.log(`El video ${idWebinar} comenzó a reproducirse.`);
        const objRequest = {
            idWebinarView: 0,
            idUser: parseInt(getJwt("jtw")),
            idEnterprise: parseInt(getJwt("enterprise")),
            idWebinar: idWebinar,
            time: 0,
        };
    
        setWebinarView(objRequest).then(response => {
            if (response) {
                if (response.validation) {
                    let newWebinarInfo = [...webinarInfo];
                    const webinarIndex = newWebinarInfo.findIndex(webinar => webinar.idWebinar === idWebinar);
    
                    if (webinarIndex !== -1) {
                        newWebinarInfo[webinarIndex] = {
                            ...newWebinarInfo[webinarIndex],
                            idWebinarView: response.data.idWebinarView,
                        };
                    } else {
                        objRequest.idWebinarView = response.data.idWebinarView;
                        newWebinarInfo.push(objRequest);
                    }
    
                    setWebinarInfo(newWebinarInfo);
                }
            }
        });
    };

    const handleOnDuration = (duration, idWebinar) => {
        //console.log(duration)
        //console.log(idWebinar)
        const backupPlayedWebinar = playedWebinar;
        const getWebinar = backupPlayedWebinar.find(webinar => webinar.idWebinar === idWebinar);
        if (getWebinar === undefined) {
            backupPlayedWebinar.push({
                idWebinar,
                duration: Math.round(duration),
            });
            setPlayedWebinar(backupPlayedWebinar);
        }
    }

    const handleOnProgress = (progress, idWebinar) => {
        // console.log('handleOnProgress progress:', progress, 'idWebinar:', idWebinar);
    
        const { played } = progress;
        const percentage = Math.round(played * 100); // Convertir progreso a porcentaje
        const webinarData = webinarInfo.find(webinar => webinar.idWebinar === idWebinar);
    
        if (!webinarData) {
            console.error(`No se encontró información del webinar con id: ${idWebinar}`);
            return;
        }

        if(webinarData.idWebinarView > 0) {
    
            let requestAllowed = false;
        
            // Actualizar hitos específicos del webinar
            setMilestones(prev => {
                const currentMilestones = prev[idWebinar] || { reached25: false, reached50: false, reached75: false, reached100: false };
                const updatedMilestones = { ...currentMilestones };
        
                if (percentage >= 25 && !currentMilestones.reached25) {
                    updatedMilestones.reached25 = true;
                    webinarData.time = 25;
                    requestAllowed = true;
                    console.log('El video alcanzó el 25%');
                }
                if (percentage >= 50 && !currentMilestones.reached50) {
                    updatedMilestones.reached50 = true;
                    webinarData.time = 50;
                    requestAllowed = true;
                    console.log('El video alcanzó el 50%');
                }
                if (percentage >= 75 && !currentMilestones.reached75) {
                    updatedMilestones.reached75 = true;
                    webinarData.time = 75;
                    requestAllowed = true;
                    console.log('El video alcanzó el 75%');
                }
                // if (percentage >= 98 && percentage < 100 && !currentMilestones.reached100) {
                //     updatedMilestones.reached100 = true;
                //     webinarData.time = 100;
                //     requestAllowed = true;
                //     console.log('El video alcanzó el 100%');
                // }
                if (percentage === 99 && !currentMilestones.reached100) {
                    updatedMilestones.reached100 = true;
                    webinarData.time = 100;
                    requestAllowed = true;
                    console.log('El video alcanzó el 100%');
                }
                
        
                return { ...prev, [idWebinar]: updatedMilestones };
            });
        
            if (requestAllowed) {
                setWebinarView(webinarData).then(response => {
                    if (response) {
                        console.log(`Progreso del webinar con id ${idWebinar} registrado con éxito.`);
                    } else {
                        console.error(`Error al registrar el progreso del webinar con id ${idWebinar}.`);
                    }
                });
            }
        }
    };
    
    const handleOnEnded = (idWebinar) => {
        console.log(`El video ${idWebinar} terminó.`);
        // Se reinician los valores
        setMilestones(prev => ({
            ...prev,
            [idWebinar]: { reached25: false, reached50: false, reached75: false, reached100: false },
        }));
    
        setWebinarInfo(prev => prev.map(webinar => 
            webinar.idWebinar === idWebinar 
                ? { ...webinar, idWebinarView: 0 } 
                : webinar
        ));
    };

    const handleOnPlay = (idWebinar) => {
        console.log(`El video ${idWebinar} se quiere reproducir.`);
        const webinar = webinarInfo.find(w => w.idWebinar === idWebinar);
    
        // Si el video ya terminó, ejecutamos handleOnStart nuevamente
        if (webinar && webinar.idWebinarView === 0) {
            handleOnStart(idWebinar);
        } else {
            console.log(`El video ${idWebinar} está reproduciéndose.`);
        }
    };

    if (redirectIfNotAuthenticated()) {
        return (
            <div className="app-content bootomFooter">
                <div className="content-overlay">
                    <Header />
                    <Menu />
                    <div className="content-wrapper bootomFooter">
                        <div className="content-header">
                            <div className="col-sx-11 col-sm-11 col-md-11 col-lg-11 mx-auto">
                                <div className="content-header-right breadcrumb-new mt-2 d-flex justify-content-end">
                                    <div className="row breadcrumbs-top d-inline-block">
                                        <div className="breadcrumb-wrapper col-12">
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item font-medium-3"><a href={getJwt('rol') === '1' ? ("/inicio-usuario") : ("/inicio-colaborador")}>Inicio</a></li>
                                                <li className="breadcrumb-item font-medium-3 text-blue-sec font-weight-bold">Webinar</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-body">
                            <HelperApp />
                            <div className="col-11 mx-auto mt-1">
                                <div className="row">
                                    {
                                        webinars.map((data, index) => (
                                            index === 2 && showBanner ? (
                                                <Fragment>
                                                    <div className="col-12 col-sm-6 col-md-4 mb-2 d-flex justify-content-center flex-wrap align-content-center p-0" key={'punlicidad-1'}>
                                                        {
                                                            getJwt('enterprise') === undefined || getJwt('enterprise') === 'undefined' ? ('') : (<BannerWebinar setShowBanner={setShowBanner} />)
                                                        }
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-4 mb-2" key={data.idWebinar}>
                                                        <div className="card m-0 h-100 hover-shadow-1">
                                                            <div className="card-img-top d-flex flex-wrap align-content-center h-100">
                                                                {data.showVideo ? (
                                                                    <ReactPlayer
                                                                        url={data.urlVideo}
                                                                        width="100%"
                                                                        height="100%"
                                                                        controls
                                                                        playing={true}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                        }}
                                                                        src={data.urlImg}
                                                                        alt="guay-digital"
                                                                        onClick={() => handleTouch(data.idWebinar)}
                                                                    />
                                                                )}
                                                            </div>
                                                            {data.showVideo && (
                                                                <div className="card-body d-flex flex-wrap align-content-center py-1">
                                                                    <h4 className="card-tilte font-weight-bold">{data.title}</h4>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            ) : (
                                                <div className="col-12 col-sm-6 col-md-4 mb-2" key={data.idWebinar}>
                                                    <div className="card m-0 h-100 hover-shadow-1">
                                                        <div className="card-img-top d-flex flex-wrap align-content-center h-100">
                                                            {data.showVideo ? (
                                                                <ReactPlayer
                                                                    url={data.urlVideo}
                                                                    width="100%"
                                                                    height="100%"
                                                                    controls
                                                                    playing={true}
                                                                    onStart={() => handleOnStart(data.idWebinar)}
                                                                    onDuration={(e) => handleOnDuration(e, data.idWebinar)}
                                                                    onProgress={(e) => handleOnProgress(e, data.idWebinar)}
                                                                    onEnded={(e) => handleOnEnded(data.idWebinar)}
                                                                    onPlay={(e) => handleOnPlay(data.idWebinar)}
                                                                    onPause={(e) => {
                                                                        console.log(e)
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    style={{
                                                                        width: "100%",
                                                                        height: "100%",
                                                                    }}
                                                                    src={data.urlImg}
                                                                    alt="guay-digital"
                                                                    onClick={() => handleTouch(data.idWebinar)}
                                                                />
                                                            )}
                                                        </div>
                                                        {data.showVideo && (
                                                            <div className="card-body d-flex flex-wrap align-content-center py-1">
                                                                <h4 className="card-tilte font-weight-bold">{data.title}</h4>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        ))
                                    }
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Webinar;
