import React from 'react';
// Tools
import Swal from "sweetalert2";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import $ from "jquery";
import { getJwt } from '../../../lib/auth';
import { connect } from 'react-redux';

const SurveyModal = ({ general, listCategory, enterpriseData, isKiosk = false, collaboratorID = 0 }) => {    
    const handleOpenURL = (url, description, title, status) => {
        try {
            switch (status) {
                case 1:
                    toastr.info('Ejercicio pendiente por lanzar.');
                    break;
                case 2:
                    Swal.fire({
                        title: '<p class="text-white mb-2">¿Deseas Continuar?</p>',
                        text: "Se abrirá una nueva ventana para realizar la evaluación " + title + ": " + description,
                        showCloseButton: true,
                        showCancelButton: true,
                        cancelButtonText: "Cancelar",
                        focusCancel: true,
                        showConfirmButton: true,
                        confirmButtonText: "Si, continuar",
                        reverseButtons: true,
                        dangerMode: true
                    }).then((willDelete) => {
                        if (willDelete.isConfirmed) {
                            window.open(url);
                        }
                    });
                    $(".swal2-title").addClass("bg-header-swal");
                    $(".swal2-actions").addClass("w-100 justify-content-around");
                    $(".swal2-confirm").removeClass("swal2-styled");
                    $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
                    $(".swal2-cancel").removeClass("swal2-styled");
                    $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
                    break;
                case 3:
                    toastr.info('Periodo del ejercicio cerrado.');
                    break;
                default:
                    toastr.error('Error al mostrar ejercicio. Por favor, intentelo más tarde.');
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleOpenUrlSurveyInde = () => {
        // Obtener el protocolo (http: o https:)
        let protocol = window.location.protocol;

        // Obtener el host (dominio)
        // let host = window.location.host;
        let url = `${protocol}/filtro-Clinico/${btoa(`${collaboratorID}-${enterpriseData.enterpriseId}`)}`;
        // console.log('url: ',url);
        Swal.fire({
            title: '<p class="text-white mb-2">¿Deseas Continuar?</p>',
            text: "Se abrirá una nueva ventana para realizar la Atención para el Bienestar Emocional",
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            focusCancel: true,
            showConfirmButton: true,
            confirmButtonText: "Si, continuar",
            reverseButtons: true,
            dangerMode: true
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                window.open(url);
            }
        });
        $(".swal2-title").addClass("bg-header-swal");
        $(".swal2-actions").addClass("w-100 justify-content-around");
        $(".swal2-confirm").removeClass("swal2-styled");
        $(".swal2-confirm").addClass("btn btn-sm btn-blue-alert mt-2");
        $(".swal2-cancel").removeClass("swal2-styled");
        $(".swal2-cancel").addClass("btn btn-sm btn-gray-alert mt-2");
    }
    return (
        <div
            className="modal"
            id="surveysModal"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div
                    className="modal-content col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto"
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                        <button
                            type="button"
                            className="close"
                            id="close" onClick={() => $('#surveysModal').hide()}>&times;</button>
                    </div>
                    {/*<!-- Modal body -->*/}
                    <div className="modal-body modal-div-style-1 container-style-1 p-2">
                        <div className="row">
                            <div className="col-12 col-lg-10 mx-auto d-flex justify-content-center mt-2">
                                <div className='row'>
                                    <div className='col-4 d-flex justify-content-center flex-wrap align-content-center'>
                                        {enterpriseData === undefined ?
                                            general.enterpriseInScope.logo !== undefined && (
                                                <img
                                                    className='img-fluid'
                                                    src={general.enterpriseInScope.logo}
                                                    alt='logo-enterprise'
                                                />
                                            )
                                            : enterpriseData.logo !== undefined && (
                                                <img
                                                    className='img-fluid'
                                                    src={enterpriseData.logo}
                                                    alt='logo-enterprise'
                                                />
                                            )}
                                    </div>
                                    <div className={`${enterpriseData === undefined ? general.enterpriseInScope.logo === undefined ? 'col-12' : 'col-8' : 'col-8'} d-flex flex-wrap align-content-center`}>
                                        <span className="font-medium-5 font-weight-bold text-center">Ingresa a tus evaluaciones correspondientes a {enterpriseData === undefined ? getJwt('enterpriseName') : enterpriseData.shortName}</span>
                                    </div>
                                </div>
                            </div>
                            {
                                ((collaboratorID !== null && collaboratorID !== '' && collaboratorID !== undefined && collaboratorID > 0) && (enterpriseData.enterpriseId !== null && enterpriseData.enterpriseId !== '' && enterpriseData.enterpriseId !== undefined && enterpriseData.enterpriseId > 0)) &&
                                <div
                                    className="col-12"
                                    key="Independiente">
                                    <div
                                        className="col-11 mx-auto modal-div-style-2 mt-2"
                                        style={{ border: "1px solid #6fbeb7" }}
                                        onClick={handleOpenUrlSurveyInde}
                                    >
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                <img
                                                    className="img-responsive"
                                                    src={"./../img/HTK_individual.png"}
                                                    alt="guay-digital" />
                                            </div>
                                            <div className="col-9 d-flex flex-wrap align-content-center p-0">
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                    <span className="font-medium-1 d-block border-bottom mb-1">ATENCIÓN PARA EL <span className='font-weight-bold'>BINESTAR EMOCIONAL</span></span>
                                                    <p className="font-small-3 d-block">Compártenos cómo te sientes para darte acciones que fortalezcan tu bienestar emocional</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div
                                        className="col-11 mx-auto modal-div-style-3 bg-menta mt-2"
                                        key="Independiente-FC-HTK"
                                    >
                                        <div className="row d-flex justify-content-center">
                                            
                                            <div className="col-11 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                <span className="fs-1-6em text-white"><span className="font-weight-bold">¿</span>Te sientes <span className="font-weight-bold">diferente?</span></span>
                                                <p className="fs-1 d-block text-white">Realiza esta prueba para descubrir qué puede ser y cómo sentirte mejor.</p>
                                            </div>
                                            <div className="col-11 d-flex justify-content-center flex-wrap align-content-center">
                                                <img
                                                    className="img-responsive"
                                                    src="../img/filtroClinico/TarjetaInicialHTK.png"
                                                    alt="guay-digital" />
                                            </div>
                                            <div className="col-11 p-0 mt-1 d-flex justify-content-center flex-wrap align-content-center">
                                                <button onClick={handleOpenUrlSurveyInde} className="btn btn-sm bg-primary-1 text-white font-weight-bold rounded-pill-1 px-1 fs-1-05em">Cuestionario</button>
                                            </div>

                                        </div>
                                    </div> */}
                                </div>
                            }
                            {listCategory.map((data, index) =>
                                <div
                                    className="col-sx-12 col-sm-12 col-md-12 col-lg-12"
                                    key={index}>
                                    {/* <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-2">
                                        <span className="text-blue font-medium-4">{data.title}</span>
                                    </div> */}
                                    {data.listSubCategorys ?
                                        data.listSubCategorys.map((data_2, index) =>
                                            <div className="col-11 mx-auto modal-div-style-2 mt-2"
                                                key={index}
                                                onClick={() => handleOpenURL(data_2.listSurvey[0].url, data_2.listSurvey[0].description, data_2.listSurvey[0].title, data_2.listSurvey[0].status)}
                                            >
                                                <img
                                                    src={data_2.icon_1}
                                                    className={`${data_2.iconId === 0 ||
                                                        data_2.iconId === 2 ||
                                                        data_2.iconId === 4 ? 'icon-survey-card-1' : 'icon-survey-card-2'
                                                        }`}
                                                    alt='icono-survey'
                                                />
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                        <img
                                                            className="img-responsive"
                                                            src={data_2.listSurvey[0].imgWeb}
                                                            alt="guay-digital" />
                                                    </div>
                                                    <div className="col-9 d-flex flex-wrap align-content-center p-0">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <span className="font-medium-1 font-weight-bold d-block border-bottom mb-1">{data_2.listSurvey[0].title}</span>
                                                            <p className="font-small-3 d-block">{data_2.listSurvey[0].description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                        null
                                    }
                                    {data.listSurvey ?
                                        data.listSurvey.map((data_2, index) =>
                                            <div
                                                className="col-11 mx-auto modal-div-style-2 mt-2"
                                                key={index}
                                                onClick={() => handleOpenURL(data_2.url, data_2.description, data_2.title, data_2.status)}
                                            >
                                                <img
                                                    src={data.icon_1}
                                                    className={`${data.iconId === 0 ||
                                                        data.iconId === 2 ||
                                                        data.iconId === 4 ? 'icon-survey-card-1' : 'icon-survey-card-2'
                                                        }`}
                                                    alt='icono-survey'
                                                />
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 p-0 d-flex justify-content-center flex-wrap align-content-center">
                                                        <img
                                                            className="img-responsive"
                                                            src={data_2.imgWeb}
                                                            alt="guay-digital" />
                                                    </div>
                                                    <div className="col-sx-9 col-sm-9 col-md-9 col-lg-9 d-flex flex-wrap align-content-center p-0">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <span className="font-medium-1 font-weight-bold d-block border-bottom mb-1">{data_2.title}</span>
                                                            <p className="font-small-3 d-block">{data_2.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                        null
                                    }
                                    {data.listCustomSurvey ?
                                        data.listCustomSurvey.map((data_1, index) =>
                                            <div
                                                className="col-11 mx-auto modal-div-style-2 mt-2"
                                                key={index}
                                                onClick={() => handleOpenURL(data_1.link, data_1.description, data_1.title, data_1.status)}
                                            >
                                                <img
                                                    src={data.icon_1}
                                                    className={`${data.iconId === 0 ||
                                                        data.iconId === 2 ||
                                                        data.iconId === 4 ? 'icon-survey-card-1' : 'icon-survey-card-2'
                                                        }`}
                                                    alt='icono-survey'
                                                />
                                                <div className="row d-flex justify-content-center">
                                                    <div className="col-sx-2 col-sm-2 col-md-2 col-lg-2 p-0">
                                                        <img className="img-card-responsive"
                                                            src={data_1.imgWeb}
                                                            alt="guay-digital" />
                                                    </div>
                                                    <div className="col-sx-9 col-sm-9 col-md-9 col-lg-9 d-flex flex-wrap align-content-center p-0">
                                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                            <span className="font-medium-1 font-weight-bold d-block border-bottom mb-1">{data_1.title}</span>
                                                            <p className="font-small-3 d-block">{data_1.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                        null
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ general }) => {
    return {
        general
    }
}

export default connect(mapStateToProps)(SurveyModal)